import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Input } from 'reactstrap';
import { useArticleBannersApi } from '../../components/ArticlesBanners/ArticlesBanners.api';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import ArticleBannertTumbnail from '../../components/ArticlesBanners/ArticleBannertTumbnail';

const ArticlesBannersField = ({ value, setFieldValue }) => {
  const lang = useSelector((state) => state.settings.lang);
  const { fetchBanners, getBanner } = useArticleBannersApi();
  const [results, setResults] = useState([]);
  const [banner, setBanner] = useState(null);

  const searchBanners = async (search) => {
    const response = await fetchBanners({
      params: {
        limit: 100,
        lang_code: lang,
        search
      }
    });

    setResults(response.data.results);
  };

  const debounceSearch = debounce((search) => {
    if (!search.length) {
      setResults([]);
      return;
    }

    searchBanners(search);
  }, 800);

  const fetchBannerFromValue = async (id) => {
    const response = await getBanner(id, lang);

    setBanner(response.data);
  };

  useEffect(() => {
    if (!!value) {
      fetchBannerFromValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="categories-input-wrapper">
      <FormGroup>
        <Input
          className="categories-input-search mb-3"
          placeholder="Wyszukaj banner"
          onChange={(e) => debounceSearch(e.target.value)}
        />
      </FormGroup>
      <Button
        type="button"
        outline
        onClick={() => {
          setResults([]);
        }}
      >
        Wyczyść
      </Button>
      <div>
        {results.map((banner) => (
          <div
            key={banner.id}
            style={{
              marginTop: '10px'
            }}
          >
            <img
              src={banner.image.file}
              alt={banner.image.id}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            />
            <Button
              type="button"
              outline
              onClick={() => {
                setFieldValue(banner.id);
                setResults([]);
              }}
              style={{
                marginTop: '10px',
                width: '100%'
              }}
            >
              Wybierz
            </Button>
          </div>
        ))}
        {!!banner && (
          <div style={{ marginTop: '10px' }}>
            <ArticleBannertTumbnail
              img={banner.image.file}
              headline={banner.headline}
              content={banner.content}
              cta={banner.cta}
            />
            <Button
              type="button"
              size="sm"
              color="danger"
              onClick={() => {
                setFieldValue(null);
                setResults([]);
              }}
              style={{
                marginTop: '10px',
                width: '100%'
              }}
            >
              Usuń
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticlesBannersField;
