/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

const ArticleBannertTumbnail = ({
  img,
  headline,
  content,
  cta,
  isMobile,
  ctaButtonText
}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        aspectRatio: isMobile ? '1/1' : '32/9',
        padding: '2rem',
        color: 'white'
      }}
    >
      <p
        style={{
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: '2rem'
        }}
      >
        {headline}
      </p>
      <p
        style={{
          fontWeight: 'bold'
        }}
      >
        {content}
      </p>
      <a
        href={cta}
        target="_blank"
        style={{
          color: 'black',
          fontWeight: 'bold',
          backgroundColor: '#ffa203',
          display: 'inline-flex',
          padding: '0.5rem 1rem',
          borderRadius: '8px',
          textDecoration: 'none',
          marginTop: '1rem'
        }}
      >
        {!!ctaButtonText ? ctaButtonText : 'Sprawdź'}
      </a>
    </div>
  );
};

export default ArticleBannertTumbnail;
