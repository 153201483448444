import { AvailableComponents } from 'agronomist-ui-components';

const ColorsName = {
  MOUNTAIN_MEADOW: 'mountainMeadow',
  SHAMROCK_GREEN: 'shamrockGreen',
  DARK_SPRING_GREEN: 'darkSpringGreen',
  MSU_GREEN: 'msuGreen',
  BRIGHT_GRAY: 'brightGray'
};


export const componentsProps = {
  [AvailableComponents.ARTICLES_SECTION]: {
    anchor: '',
    py: 0,
    title: 'Wciąż nie znalazłeś jak możemy Ci pomóc?',
    subtitle: 'Dedykowana oferta, tylko dla rolników.',
    linkToAll: '#',
    toAllText: 'Wszystkie wpisy',
    articles: [
      {
        coverUrl:
          'https://i.picsum.photos/id/338/300/200.jpg?hmac=-0-kbxKPQKZyWfrQcFSF9OR_w2jf15p54LpLXcq2Lq4',
        date: '14.02.2021',
        tag: {
          name: 'tag',
          url: '#'
        },
        title:
          'Rok lockdownów — jak pandemia COVID-19 wpłynęła na zachowania konsumentów i finanse osobiste',
        description:
          'Dziecku, jak każdemu człowiekowi, należy się rzetelna informacja. W obliczu zagrożenia epidemią, najgorzej udawać, że nic się nie dzieje. Pozostawione same sobie dzieci mogą spotkać się z dezinformacją i plotkami, co może wzmagać w nich lęk i niepewność. Lęk z kolei obniża układ odpornościowy i powoduje irracjonalne zachowania, a nawet panikę. Dla dobra dzieci, powinniśmy pomóc im oswoić lęk poprzez rzeczową i merytoryczną rozmowę na temat aktualnej sytuacji epidemicznej w Polsce i na świecie, jak również samego wirusa.'
      },
      {
        coverUrl:
          'https://i.picsum.photos/id/338/300/200.jpg?hmac=-0-kbxKPQKZyWfrQcFSF9OR_w2jf15p54LpLXcq2Lq4',
        date: '14.02.2021',
        tag: {
          name: 'tag',
          url: '#'
        },
        title: 'Lorem ipsum dolor sit amet.',
        description:
          'Duis ut ultricies ante, non consectetur purus. Nullam sapien enim, porttitor ac dolor in, molestie pulvinar purus. In purus quam.'
      },
      {
        coverUrl:
          'https://i.picsum.photos/id/338/300/200.jpg?hmac=-0-kbxKPQKZyWfrQcFSF9OR_w2jf15p54LpLXcq2Lq4',
        date: '14.02.2021',
        tag: {
          name: 'tag',
          url: '#'
        },
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        description:
          'Duis ut ultricies ante, non consectetur purus. Nullam sapien enim, porttitor ac dolor in, molestie pulvinar purus. In purus quam.'
      },
      {
        coverUrl:
          'https://i.picsum.photos/id/338/300/200.jpg?hmac=-0-kbxKPQKZyWfrQcFSF9OR_w2jf15p54LpLXcq2Lq4',
        date: '14.02.2021',
        tag: {
          name: 'tag',
          url: '#'
        },
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        description:
          'Duis ut ultricies ante, non consectetur purus. Nullam sapien enim, porttitor ac dolor in, molestie pulvinar purus. In purus quam.'
      }
    ]
  },
  [AvailableComponents.BENEFITS]: {
    anchor: '',
    py: 0,
    title: 'Jakie są korzyści z finansowania?',
    subtitle:
      'Moduł Produktowy to prosta i przyjazna wyszukiwarka produktów bankowych ułatwiająca dobór oferty najbardziej dopasowanej do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.',
    benefits: [
      {
        title: 'Niski udział własny',
        description: 'nie wymagany biznes plan',
        icon: 'PaperShield'
      },
      {
        title: 'Niski udział własny',
        description: 'nie wymagany biznes plan',
        icon: 'PaperShield'
      },
      {
        title: 'Niski udział własny',
        description: 'nie wymagany biznes plan',
        icon: 'PaperShield'
      }
    ],
    withArrow: false
  },
  [AvailableComponents.CONTACT_FORM]: {
    py: 0,
    anchor: 'contact'
  },
  [AvailableComponents.DISCLAIMER]: {
    anchor: '',
    py: 0,
    notes: [
      'Opłata za korzystanie z bankomatów innych operatorów zgodna z taryfą prowizji i opłat dostępną w oddziałach Banku oraz na stronie www.bnpparibas.pl. Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Ustawy z dnia 23 kwietnia 1964 roku Kodeks cywilny.',
      'Świadczenie usług i oferowanie produktów bankowych następuje przez BNP Paribas Bank Polska Spółka Akcyjna z siedzibą w Warszawie (01-211) przy ul. Kasprzaka 10/16, zarejestrowany w rejestrze przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod nr. KRS 0000011571, posiadający NIP 526-10-08-546 oraz kapitał zakładowy w wysokości 147 418 918 zł, w całości wpłacony.'
    ]
  },
  [AvailableComponents.DOWNLOAD_DOCUMENTS]: {
    anchor: '',
    py: 0,
    files: [
      {
        name: 'Załącznik do oferty 1',
        details: 'Produkt 2021, Plik .pdf 3.4mb',
        url: '#'
      }
    ]
  },
  [AvailableComponents.HERO]: {
    anchor: '',
    py: 0,
    breadcrumbs: [
      {
        title: 'Agronomist',
        url: '#'
      },
      {
        title: 'Narzędzia',
        url: '#'
      },
      {
        title: 'Sprawdź możliwości'
      }
    ],
    title: 'Postaw na rozwój swojego gospodarstwa',
    description:
      'Poszukujesz finansowania, prostego i szybkiego dostępu do rachunku poprzez bankowość elektroniczną, chciałbyś ubezpieczyć swój majątek lub porozmawiać o swoich potrzebach z Ekspertem Agro?',
    imageUrl:
      'https://i.picsum.photos/id/103/2592/1936.jpg?hmac=aC1FT3vX9bCVMIT-KXjHLhP6vImAcsyGCH49vVkAjPQ',
    button: {
      title: 'Sprawdź ofertę',
      link: '/'
    },
    isImageFullSize: false,
    mobileImageUrl: 'https://picsum.photos/200',
    isLight: false
  },
  [AvailableComponents.INFORMATION_SECTION]: {
    anchor: '',
    py: 0,
    title: 'Jakie są korzyści z finansowania?',
    subtitle:
      'Moduł Produktowy to prosta i przyjazna wyszukiwarka produktów bankowych ułatwiająca dobór oferty najbardziej dopasowanej do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.',
    benefits: [
      {
        title: 'Bezpieczeństwo i łatwy dostęp do zgromadzonych środków',
        icon: 'Shield'
      },
      {
        title: 'Blisko 500 placówek bankowych oferujących usługi bankowe',
        icon: 'MapMarker'
      },
      {
        title: 'Wsparcie dedykowanego doradcy w sprawach bankowych',
        icon: 'MessagesEllipsis'
      },
      {
        title: 'Innowacyjne rozwiązania i usługi dodatkowe',
        icon: 'Signposts'
      }
    ]
  },
  [AvailableComponents.INSURANCE_SCOPE]: {
    anchor: '',
    py: 0,
    title: 'Przedmiot ubezpieczenia',
    description:
      'Obowiązkowe ubezpieczenie budynków rolniczych możesz uzupełnić o dobrowolne ubezpieczenie budynków rolniczych w ramach wariantu Rozszerzonego lub All Risks',
    items: [
      'OC komunikacyjne',
      'OC komunikacyjne',
      'OC komunikacyjne',
      'Autoszyby',
      'Autoszyby'
    ],
    icon: 'Star'
  },
  [AvailableComponents.INVESTMENT_CALCULATOR_PLACEHOLDER]: {
    anchor: '',
    py: 0
  },
  [AvailableComponents.LOGOS]: {
    anchor: '',
    py: 0
  },
  [AvailableComponents.LOGOS_ARVAL]: {
    anchor: '',
    py: 0,
    link: ''
  },
  [AvailableComponents.VIDEO]: {
    anchor: '',
    py: 0,
    title: 'Sprawdź jak możesz wyszukać odpowiedni produkt',
    subtitle:
      'Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    youTubeVideoUrl: 'https://www.youtube.com/embed/lgQOfO-EZD8'
  },
  [AvailableComponents.PACKAGE_TABLE]: {
    anchor: '',
    py: 0,
    packages: [
      {
        label: 'congue libero A',
        slug: 'a'
      },
      {
        label: 'congue libero B',
        slug: 'b'
      },
      {
        label: 'congue libero C',
        slug: 'c'
      },
      {
        label: 'congue libero XL',
        slug: 'xl'
      }
    ],
    filters: [
      {
        label: 'filter A',
        includes: 'a,b,c'
      },
      {
        label: 'filter C',
        includes: 'c'
      },
      {
        label: 'filter XLA',
        includes: 'a,xl'
      }
    ],
    features: {
      a: [
        {
          label: 'Donec',
          color: ColorsName.MOUNTAIN_MEADOW
        },
        {
          label: 'Nullam tincidunt',
          color: ColorsName.SHAMROCK_GREEN
        },
        {
          label: 'Luctus velit',
          color: ColorsName.DARK_SPRING_GREEN
        },
        {
          label: 'Accumsan dui elementum',
          color: ColorsName.MSU_GREEN
        }
      ],
      b: [
        {
          label: 'Donec',
          color: ColorsName.MOUNTAIN_MEADOW
        },
        {
          label: 'Nullam tincidunt',
          color: ColorsName.SHAMROCK_GREEN
        },
        {
          label: 'Luctus velit',
          color: ColorsName.DARK_SPRING_GREEN
        },
        {
          label: '-',
          color: ColorsName.BRIGHT_GRAY
        }
      ],
      c: [
        {
          label: 'Donec',
          color: ColorsName.MOUNTAIN_MEADOW
        },
        {
          label: 'Nullam tincidunt',
          color: ColorsName.SHAMROCK_GREEN
        },
        {
          label: '-',
          color: ColorsName.BRIGHT_GRAY
        },
        {
          label: '-',
          color: ColorsName.BRIGHT_GRAY
        }
      ],
      xl: [
        {
          label: 'Donec',
          color: ColorsName.MOUNTAIN_MEADOW
        },
        {
          label: '-',
          color: ColorsName.BRIGHT_GRAY
        },
        {
          label: '-',
          color: ColorsName.BRIGHT_GRAY
        },
        {
          label: '-',
          color: ColorsName.BRIGHT_GRAY
        }
      ]
    },
    filterTitle: 'Wybierz kategorię ubezepieczeń',
    disclaimer:
      'Wszystkie warunki ubezpieczeń dostępne są w załączniku nr 1 oraz u naszych autoryzowanych sprzedawców. Ogień (opcja) dotyczy upraw: kukurydza, rośliny oleiste, rośliny strączkowe, zboża jare i ozime.'
  },
  [AvailableComponents.BENEFITS_TABLE]: {
    anchor: '',
    py: 0,
    packages: [
      'Lorem ipsum',
      'Dolor sit amet',
      'Suspendisse quam ligula',
      'Finibus et leo ac'
    ],
    features: [
      {
        isRecommended: true,
        title: 'Sed in venenatis orci',
        subtitle: 'Test',
        list: ['Donec<sub>1</sub>', 'Donec', 'Donec', '<p>Donec</p>'],
        footer: {
          text: 'Aenean sed',
          link: ''
        },
        annotation: 'Możiwość konsolidacji oraz ubezpieczenia CARDIFF'
      },
      {
        title: 'Finibus',
        list: [
          'Nullam tincidunt',
          'Nullam tincidunt',
          'Nullam tincidunt',
          'Nullam tincidunt'
        ],
        footer: {
          text: 'Aenean sed',
          link: ''
        }
      },
      {
        title: 'Curabitur eleifend',
        list: ['Luctus velit', 'Luctus velit', 'Luctus velit', 'Luctus velit'],
        footer: {
          text: 'Aenean sed',
          link: ''
        }
      }
    ],
    footer: {
      text: 'Vivamus sollicitudin scelerisque eros ut commodo. Suspendisse quam ligula',
      link: '#',
      moreText: 'Test'
    },
    action: null
  },
  [AvailableComponents.INCLUDED_SERVICES_LIST]: {
    anchor: '',
    py: 0,
    background: '#ececec',
    justifyContent: 'flex-start',
    title: 'Twoja miesięczna rata w wynajmie zawiera:',
    services: [
      {
        title: 'Obsługa serwisowa',
        description:
          'Wszystkie naprawy serwisowe, obsługę techniczną i przeglądy przeprowadzamy bezpłatnie w ramach Twojej miesięcznej raty.',
        icon: 'Wrench'
      },
      {
        title: 'Assistance',
        description: 'Otrzymujesz opiekę Assistance 24 godziny na dobę.',
        icon: 'CallcenterHeadset'
      },
      {
        title: 'Samochód zastępczy',
        description:
          'W przypadku unieruchomienia Twojego pojazdu, możesz skorzystać z samochodu zastępczego.',
        icon: 'Car'
      },
      {
        title: 'Ubezpieczenie',
        description:
          'Wraz z samochodem otrzymujesz pełen pakiet ubezpieczeń: OC, AC oraz NNW.',
        icon: 'Shield'
      },
      {
        title: 'Karty paliwowe',
        description:
          'Bez względu na liczbę wynajmowanych samochodów otrzymujesz karty paliwowe, a także atrakcyjne rabaty na paliwo.',
        icon: 'Distributor'
      },
      {
        title: 'Aplikacja My Arval Mobile',
        description:
          'Dzięki aplikacji My Arval Mobile otrzymasz wsparcie w codziennym użytkowaniu Twojego samochodu np. przy wyszukiwaniu serwisu.',
        icon: 'Mobile'
      },
      {
        title: 'Finansowanie',
        description:
          'Otrzymujesz atrakcyjne finansowanie na okres od 30 do 60 miesięcy. Nie wymagamy wpłaty własnej.',
        icon: 'Money'
      }
    ]
  },
  [AvailableComponents.PACKAGE_DETAILS]: {
    anchor: '',
    py: 0,
    isReverse: false,
    background: '#00965e',
    imageSrc: 'https://picsum.photos/500',
    isLight: true,
    list: {
      title: 'Konto otwarte na Agrobiznes',
      description:
        'Sprawdź nasze konto i dodatkowe usługi z nim związane dedykowane mniejszym gospodarstwom rolnym. To idealne rozwiązanie dla Ciebie! Co oferujemy:',
      items: [
        'od 0 zł za prowadzenie rachunku bieżącego w PLN za m-c',
        'od 0 zł za bankowość internetową, mobilną oraz telefoniczną za m-c',
        '0 zł za wydanie karty debetowej w PLN i EUR oraz multiwalutowej',
        'od 0 zł za użytkowanie karty debetowej w PLN',
        '0 zł za przelewy internetowe krajowe wewnętrzne i wychodzące w PLN i EUR'
      ]
    },
    btnText: 'Zapytaj o ofertę'
  },
  [AvailableComponents.HEADLINE_SECTION]: {
    py: 0,
    title: 'Dlaczego warto wybrać ubezpieczenie upraw?',
    subtitle:
      'Moduł Produktowy to prosta i przyjazna wyszukiwarka produktów bankowych ułatwiająca dobór oferty najbardziej dopasowanej do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.',
    btnText: 'Tekst',
    link: '/',
    backgroundColor: '#00965e',
    color: 'white',
    backgroundColorItem: 'green',
    list: [],
    style: {}
  },
  [AvailableComponents.SHARE_PLACEHOLDER]: {
    anchor: '',
    py: 0
  },
  [AvailableComponents.IMAGE_GRID]: {
    anchor: '',
    py: 0,
    md:6,
    lg:3,
    disclaimer: null,
    images: [],
  }
};

export const componentsPropsUI = {
  [AvailableComponents.ARTICLES_SECTION]: {
    name: 'Artykuły',
    img: ''
  },
  [AvailableComponents.BENEFITS]: {
    name: 'Benefity',
    img: ''
  },
  [AvailableComponents.CONTACT_FORM]: {
    name: 'Formularz kontaktowy',
    img: ''
  },
  [AvailableComponents.DISCLAIMER]: {
    name: 'Disclaimer',
    img: ''
  },
  [AvailableComponents.DOWNLOAD_DOCUMENTS]: {
    name: 'Dokumenty',
    img: ''
  },
  [AvailableComponents.HERO]: {
    name: 'Hero',
    img: ''
  },
  [AvailableComponents.INFORMATION_SECTION]: {
    name: 'Sekcja informacyjna',
    img: ''
  },
  [AvailableComponents.INSURANCE_SCOPE]: {
    name: 'Sekcja ubezpieczeń',
    img: ''
  },
  [AvailableComponents.INVESTMENT_CALCULATOR_PLACEHOLDER]: {
    name: 'Kalkulator inwestycyjny',
    img: ''
  },
  [AvailableComponents.LOGOS]: {
    name: 'Logotypy (LEGACY)',
    img: ''
  },
  [AvailableComponents.LOGOS_ARVAL]: {
    name: 'Logotypy - Arval (LEGACY)',
    img: ''
  },
  [AvailableComponents.VIDEO]: {
    name: 'Wideo - YouTube',
    img: ''
  },
  [AvailableComponents.PACKAGE_TABLE]: {
    name: 'Tabela pakietów',
    img: ''
  },
  [AvailableComponents.BENEFITS_TABLE]: {
    name: 'Tabela Benefitów',
    img: ''
  },
  [AvailableComponents.PACKAGE_DETAILS]: {
    name: 'Detale pakietu',
    img: ''
  },
  [AvailableComponents.INCLUDED_SERVICES_LIST]: {
    name: 'Lista serwisów',
    img: ''
  },
  [AvailableComponents.HEADLINE_SECTION]: {
    name: 'Headline',
    img: ''
  },
  [AvailableComponents.SHARE_PLACEHOLDER]: {
    name: 'Udostępnij',
    img: ''
  },
  [AvailableComponents.IMAGE_GRID]: {
    name: 'Siatka obrazów',
    img: '',
  }
};
