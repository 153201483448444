import React from 'react';
import { useFormik } from 'formik';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Container,
  Button
} from 'reactstrap';
import debounce from 'lodash.debounce';
import Inputs from '../../Inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faAngleDown,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons';

const ImageGridForm = ({ notes, onChange, ...props }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      anchor: props.anchor,
      py: props.py,
      images: props.images,
      disclaimer: props.disclaimer,
      md: props.md,
      lg: props.lg
    },
    onSubmit: (values) => {
      onChange(values);
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  function changeArrayElementPosition(oldIndex, newIndex) {
    const arr = JSON.parse(JSON.stringify(formik.values.images));

    // Check if the indices are valid
    if (
      oldIndex < 0 ||
      oldIndex >= arr.length ||
      newIndex < 0 ||
      newIndex >= arr.length
    ) {
      console.error('Invalid index provided.');
      return arr; // Return the original array if indices are invalid
    }

    // Remove the element from the old index
    const element = arr.splice(oldIndex, 1)[0];

    // Insert the element at the new index
    arr.splice(newIndex, 0, element);

    return formik.setFieldValue('images', arr);
  }

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Container className="prf-editor" fluid>
      <Row>
        <Col>
          <p className="prf-editor__title">Lista obrazków</p>
          <div className="prf-editor__image-grid">
            {formik.values.images.map((image, idx) => (
              <div
                className="prf-editor__image-grid-item"
                style={{
                  backgroundImage: `url("${image.url}")`
                }}
                key={`${image.url}_${idx}`}
              >
                <div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '20px'
                    }}
                  >
                    {idx !== 0 && (
                      <FontAwesomeIcon
                        icon={faAngleUp}
                        onClick={() => changeArrayElementPosition(idx, idx - 1)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    {idx !== formik.values.images.length - 1 && (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        onClick={() => changeArrayElementPosition(idx, idx + 1)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </div>
                  <FormGroup>
                    <Label for="disclaimer">Disclaimer</Label>
                    <Input
                      id="disclaimer"
                      name="anchor"
                      type="text"
                      value={formik.values.images[idx].disclaimer}
                      onBlur={onBlur}
                      onChange={({ target }) => {
                        formik.setFieldValue(
                          `images`,
                          formik.values.images.map((img, i) => {
                            if (i === idx) {
                              return {
                                ...img,
                                disclaimer: target.value
                              };
                            }

                            return img;
                          })
                        );
                        debounceSave();
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="anchor">Link URL</Label>
                    <Input
                      id="linkUrl"
                      name="linkUrl"
                      type="text"
                      value={formik.values.images[idx].linkUrl}
                      onBlur={onBlur}
                      onChange={({ target }) => {
                        formik.setFieldValue(
                          `images`,
                          formik.values.images.map((img, i) => {
                            if (i === idx) {
                              return {
                                ...img,
                                linkUrl: target.value
                              };
                            }

                            return img;
                          })
                        );

                        debounceSave();
                      }}
                    />
                  </FormGroup>
                  <Button
                    size="sm"
                    onClick={() => {
                      formik.setFieldValue(
                        `images`,
                        formik.values.images.filter((_, i) => i !== idx)
                      );

                      debounceSave();
                    }}
                    color="danger"
                  >
                    Usuń
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <hr />

          <Inputs.MediaButton
            type="image"
            onChange={(url) => {
              formik.setFieldValue(`images`, [
                ...formik.values.images,
                {
                  url,
                  disclaimer: null,
                  linkUrl: null
                }
              ]);
              debounceSave();
            }}
          />
        </Col>
        <Col>
          <p className="prf-editor__title">Ustawienia ogólne</p>
          <Inputs.SpacesInput
            id="py"
            name="py"
            value={formik.values.py}
            invalid={!!formik.errors.py}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.value);
            }}
          />
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="disclaimer">Disclaimer</Label>
            <Input
              id="disclaimer"
              name="disclaimer"
              type="text"
              value={formik.values.disclaimer}
              invalid={!!formik.errors.disclaimer}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('disclaimer', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="anchor">
              Maksymalna ilość obrazków w rzędzie(duży ekran)
            </Label>
            <select
              name="lg"
              id="lg"
              style={{ width: '100%', padding: '8px' }}
              value={`${12 / formik.values.lg}`}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(
                  'lg',
                  Math.round(12 / Number(target.value))
                );
                debounceSave();
              }}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
          </FormGroup>
          <FormGroup>
            <Label for="md">
              Maksymalna ilość obrazków w rzędzie (mały ekran)
            </Label>
            <select
              style={{ width: '100%', padding: '8px' }}
              name="md"
              id="md"
              value={`${12 / formik.values.md}`}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(
                  'md',
                  Math.round(12 / Number(target.value))
                );
                debounceSave();
              }}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default ImageGridForm;
