import React from 'react';
import Layout from '../../components/Layout';
import ArticlesBannerForm from '../../components/ArticlesBanners/ArticlesBannerForm';
import EditArticlesBanner from './EditArticlesBanner';

function AddArticlesBannersPage({ match }) {
  if (match.params.id === 'add') {
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">Dodaj nowy baner</h1>
        <ArticlesBannerForm />
      </Layout>
    );
  }

  return <EditArticlesBanner id={match.params.id} />;
}

export default AddArticlesBannersPage;
