import React from 'react';
import { Button } from 'reactstrap';
import Layout from '../../components/Layout';
import ArticlesBannersList from '../../components/ArticlesBanners/ArticlesBannersList';

function ArticlesBannersPage({ history }) {
  return (
    <Layout>
      <h1 className="h2 pb-md-4 page-header">
        Banery dla artykułów
        <Button
          className="ms-3"
          color="primary"
          onClick={() => history.push('/articles-banners/add')}
        >
          Dodaj nowy
        </Button>
      </h1>
      <h4>Lista banerów</h4>
      <ArticlesBannersList />
    </Layout>
  );
}

export default ArticlesBannersPage;
