import { useFormik } from 'formik';
import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Col,
  Card,
  CardBody,
  Spinner
} from 'reactstrap';
import Inputs from '../../features/prf/components/Inputs';
import * as Yup from 'yup';
import { useArticleBannersApi } from './ArticlesBanners.api';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ArticleBannertTumbnail from './ArticleBannertTumbnail';

function ArticlesBannerForm({ data }) {
  const lang = useSelector((state) => state.settings.lang);
  const { createBanner, editBanner } = useArticleBannersApi();
  const history = useHistory();

  const form = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      image_id: Yup.string().required('Pole wymagane')
    }),
    initialValues: {
      headline: data ? data.headline : '',
      content: data ? data.content : '',
      cta_button_text: data ? data.cta_button_text : 'SPRAWDŹ',
      cta: data ? data.cta : '',
      image_id: data ? data.image.id : '',
      mobile_image_id: data
        ? !!data.mobile_image
          ? data.mobile_image.id
          : ''
        : '',
      default: data ? data.default : false,
      image_src: data ? data.image.file : null,
      mobile_image_src: data
        ? !!data.mobile_image
          ? data.mobile_image.file
          : null
        : null,
      meta: {}
    },
    onSubmit: async (values, tool) => {
      const date = new Date();

      tool.setSubmitting(true);

      try {
        if (!!data) {
          await editBanner({
            id: data.id,
            lang_code: data.lang_code,
            headline: values.headline,
            content: values.content,
            cta: values.cta.startsWith('www.')
              ? `https://${values.cta}`
              : values.cta,
            cta_button_text: values.cta_button_text,
            image_id: values.image_id,
            mobile_image_id: values.mobile_image_id,
            default: values.default,
            set_default: !!values.default ? date.toISOString() : null,
            meta: values.meta
          });

          toast.success('Zapisano');
        } else {
          await createBanner({
            lang_code: lang,
            headline: values.headline,
            content: values.content,
            cta: values.cta.startsWith('www.')
              ? `https://${values.cta}`
              : values.cta,
            cta_button_text: values.cta_button_text,
            image_id: values.image_id,
            mobile_image_id: values.mobile_image_id,
            default: values.default,
            set_default: !!values.default ? date.toISOString() : null,
            meta: values.meta
          });

          history.push('/articles-banners');
        }
      } catch (error) {
        toast.error('Nie udało się zapisać');
      } finally {
        tool.setSubmitting(false);
      }
    }
  });

  return (
    <div>
      <Form className="row" noValidate onSubmit={form.handleSubmit}>
        <Col sm="8">
          <Card>
            <CardBody>
              <FormGroup>
                <Label for="headline">Nagłówek</Label>
                <Input
                  type="text"
                  name="headline"
                  id="headline"
                  required
                  value={form.values.headline}
                  onChange={({ target }) =>
                    form.setFieldValue(`headline`, target.value)
                  }
                  invalid={!!form.errors ? form.errors.headline : false}
                />
                <FormFeedback>
                  {!!form.errors ? form.errors.headline : false}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="content">Treść</Label>
                <Input
                  type="text"
                  name="content"
                  id="content"
                  required
                  value={form.values.content}
                  onChange={({ target }) =>
                    form.setFieldValue(`content`, target.value)
                  }
                  invalid={!!form.errors ? form.errors.content : false}
                />
                <FormFeedback>
                  {!!form.errors ? form.errors.content : false}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="cta_button_text">Etykieta CTA</Label>
                <Input
                  type="text"
                  name="cta_button_text"
                  id="cta_button_text"
                  required
                  value={form.values.cta_button_text}
                  onChange={({ target }) =>
                    form.setFieldValue(`cta_button_text`, target.value)
                  }
                  invalid={!!form.errors ? form.errors.cta_button_text : false}
                />
                <FormFeedback>
                  {!!form.errors ? form.errors.cta_button_text : false}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="cta">URL CTA</Label>
                <Input
                  type="text"
                  name="cta"
                  id="cta"
                  required
                  value={form.values.cta}
                  onChange={({ target }) =>
                    form.setFieldValue(`cta`, target.value)
                  }
                  invalid={!!form.errors ? form.errors.cta : false}
                />
                <FormFeedback>
                  {!!form.errors ? form.errors.cta : false}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label
                  for="image_id"
                  style={{
                    display: 'block'
                  }}
                >
                  Baner desktop*
                </Label>
                <Inputs.MediaButton
                  type="image"
                  onChange={(url, id) => {
                    form.setValues({
                      ...form.values,
                      image_id: id,
                      image_src: url
                    });
                  }}
                />
                <FormFeedback
                  style={{
                    display: 'block',
                    marginTop: '-0.5rem'
                  }}
                  invalid={
                    !!form.errors ? `${!!form.errors.image_id}` : 'false'
                  }
                >
                  {!!form.errors ? form.errors.image_id : false}
                </FormFeedback>
                {!!form.values.image_src && (
                  <ArticleBannertTumbnail
                    img={form.values.image_src}
                    headline={form.values.headline}
                    content={form.values.content}
                    cta={form.values.cta}
                    ctaButtonText={form.values.cta_button_text}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Label
                  for="mobile_image_id"
                  style={{
                    display: 'block'
                  }}
                >
                  Baner mobile
                </Label>
                <Inputs.MediaButton
                  type="image"
                  onChange={(url, id) => {
                    form.setValues({
                      ...form.values,
                      mobile_image_id: id,
                      mobile_image_src: url
                    });
                  }}
                />
                {!!form.values.mobile_image_src && (
                  <ArticleBannertTumbnail
                    img={form.values.mobile_image_src}
                    headline={form.values.headline}
                    content={form.values.content}
                    cta={form.values.cta}
                    ctaButtonText={form.values.cta_button_text}
                    isMobile
                  />
                )}
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
        <Col sm="4">
          <Card className="card--sticky">
            <CardBody
              style={{
                position: 'sticky',
                top: 0
              }}
            >
              <FormGroup check style={{ marginBottom: '1rem' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    name="default"
                    onChange={({ target }) => {
                      form.setFieldValue(`default`, target.checked);
                    }}
                    checked={!!form.values.default}
                  />
                  Ustaw jako domyślny
                </Label>
              </FormGroup>
              <div
                className="mt-sm-2"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  type="submit"
                  color="primary"
                  disabled={form.isSubmitting}
                >
                  {form.isSubmitting ? <Spinner /> : 'Zapisz'}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Form>
    </div>
  );
}

export default ArticlesBannerForm;
