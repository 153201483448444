import React, { useState } from 'react';
import { useCategories } from '../../common/Articles/useCategories';
import ReactTable from 'react-table';
import { Button, Input } from 'reactstrap';
import API, { frontLink } from '../../services/api';
import { formatDate } from '../../utilities/dates';
import { displayStatus, statuses } from '../../utilities/status';
import { useSelector } from 'react-redux';
import { DeleteButton } from '../FunctionalButtons/DeleteButton';
import { calculateMaxPages } from '../../utilities/calculates';
import { useHistory } from 'react-router';
import { useArticleBannersApi } from './ArticlesBanners.api';
import ArticleBannertTumbnail from './ArticleBannertTumbnail';

const ArticlesBannersList = ({ selectMode, selectArticle }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(null);
  const [tableState, setTableState] = useState({});
  const slug = 'articles-banners';
  const lang = useSelector((state) => state.settings.lang);
  const token = useSelector((state) => state.user.token.key);
  const { fetchBanners, removeBanner } = useArticleBannersApi();

  const { flattenCategories } = useCategories();

  const categories = flattenCategories() || [];

  const fetchData = (state) => {
    setLoading(true);
    setTableState(state);

    const params = {
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      lang_code: lang
    };

    if (state.filtered.length) {
      state.filtered.forEach((element) => {
        if (element.id === 'name') {
          params.search = element.value;
        } else if (element.id === 'article_type') {
          params[`${element.id}_id`] = element.value;
        } else {
          params[`${element.id}`] = element.value;
        }
      });
    }

    let sort = [];

    if (state.sorted.length) {
      sort.push(`${state.sorted[0].desc ? '-' : ''}${state.sorted[0].id}`);
    } else {
      sort.push(`-created_at`);
    }

    const sortingParams = sort.length
      ? sort.reduce((a, b) => {
          return a + ',' + b;
        })
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        ...params,
        ordering: sortingParams
      }
    };

    return fetchBanners(config)
      .then(({ data }) => {
        setData(data.results);
        setPages(calculateMaxPages(data.count, state.pageSize));
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveArticle = (slug) => {
    removeBanner(slug).then(() => {
      fetchData(tableState);
    });
  };

  return (
    <div className="table-responsive">
      <ReactTable
        columns={[
          {
            Header: 'ID.',
            Cell: (row) => {
              return <div>{row.original.id}</div>;
            },
            filterable: false,
            maxWidth: 40
          },
          {
            Header: 'Obrazek',
            accessor: 'name',
            filterable: false,
            Cell: (row) => {
              return (
                <ArticleBannertTumbnail
                  img={row.original.image.file}
                  headline={row.original.headline}
                  content={row.original.content}
                  cta={row.original.cta}
                />
              );
            }
          },

          {
            Header: 'Domyślny',
            id: 'default',
            accessor: (d) => (d.default ? 'Tak' : 'Nie'),
            filterable: false,
            maxWidth: 210,
            sortable: true
          },

          {
            Header: 'Akcje',
            Cell: (d) => {
              return (
                <>
                  {selectMode ? (
                    <>
                      <Button
                        color="primary"
                        onClick={() => selectArticle(d.original)}
                      >
                        Wybierz
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        onClick={() =>
                          history.push(`/${slug}/${d.original.id}`)
                        }
                        size="sm"
                      >
                        Edycja
                      </Button>
                      <DeleteButton
                        callback={() => handleRemoveArticle(d.original.id)}
                      />
                    </>
                  )}
                </>
              );
            },
            filterable: false,
            maxWidth: 210
          }
        ]}
        manual
        data={data}
        pages={pages}
        filterable
        sortable={false}
        resizable={false}
        onFetchData={fetchData}
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </div>
  );
};

export default ArticlesBannersList;
