import React, { Component } from 'react';
import Layout from '../../components/Layout/index.jsx';
import UserForm from '../../components/Forms/UserForm';

class NewUser extends Component {
  render() {
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">Nowy użytkownik</h1>
        {/* Content */}
        <UserForm goBack={() => this.history.push('/users')} />
      </Layout>
    );
  }
}

export default NewUser;
