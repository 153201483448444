import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faAngleDown,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons';
import { CardTitle, Card, CardBody, Row, Col } from 'reactstrap';

import { componentsForms } from './elementsForms.utils';
import { componentsPropsUI } from '../../components/ProductEditActions/ProductEditActions.utils';

const ElementForm = ({ component, onUpdate }) => {
  const Element = componentsForms[component.type];

  if (!!Element) {
    return (
      <Element
        {...component.props}
        onChange={(newProps) => onUpdate({ ...component, props: newProps })}
      />
    );
  }

  return null;
};

const ElementsForms = ({
  page,
  onRemove,
  onMoveTop,
  onMoveButton,
  onUpdate,
  language
}) => {
  const lastIndex = useMemo(() => page[language].length - 1, [page, language]);
  const pageCount = useMemo(() => page[language].length, [page, language]);

  return (
    <>
      {page[language].map((component, idx) => {
        const componentPropsUI = componentsPropsUI[component.type];
        const componentName = componentPropsUI ? componentPropsUI.name : component.type;

        return (
          <Card
            style={{
              backgroundColor: '#F5F5F5',
              borderColor: '#F5F5F5',
              margin: '8px 0'
            }}
            key={`edit_${component.id}_${language}`}
          >
            <CardBody>
              <CardTitle tag="h5">
                <Row>
                  <Col xs={6}>{componentName}</Col>
                  <Col xs={6} style={{ textAlign: 'right' }}>
                    {idx !== 0 && (
                      <FontAwesomeIcon
                        style={{ margin: '0 4px' }}
                        icon={faAngleUp}
                        onClick={() => onMoveTop(idx)}
                      />
                    )}
                    {lastIndex !== idx && pageCount > 1 && (
                      <FontAwesomeIcon
                        style={{ margin: '0 4px' }}
                        icon={faAngleDown}
                        onClick={() => onMoveButton(idx)}
                      />
                    )}
                    <FontAwesomeIcon
                      style={{ margin: '0 4px 0 28px' }}
                      icon={faTrashAlt}
                      onClick={() => onRemove(component.id)}
                    />
                  </Col>
                </Row>
              </CardTitle>
              <ElementForm component={component} onUpdate={onUpdate} />
            </CardBody>
          </Card>
        );
      })}
    </>
  );
};

export default ElementsForms;
