import { AvailableComponents } from 'agronomist-ui-components';

import DisclaimerForm from './forms/DisclaimerForm';
import BenefitsForm from './forms/BenefitsForm';
import ArticlesSectionForm from './forms/ArticlesSectionForm';
import VideoForm from './forms/VideoForm';
import HeroForm from './forms/HeroForm';
import InformationSectionForm from './forms/InformationSectionForm';
import InsuranceScopeForm from './forms/InsuranceScopeForm';
import SpaceForm from './forms/SpaceForm';
import BenefitsTableForm from './forms/BenefitsTableForm';
import PackageTableForms from './forms/PackageTableForms';
import DocumentsForm from './forms/DocumentsForm';
import IncludedServicesListForm from './forms/IncludedServicesListForm';
import PackageDetailsForm from './forms/PackageDetailsForm';
import HeadlineForm from './forms/HeadlineForm';
import LogosArvalForm from './forms/LogosArvalForm';
import ImageGridForm from './forms/ImageGridForm';

export const componentsForms = {
  [AvailableComponents.ARTICLES_SECTION]: ArticlesSectionForm,
  [AvailableComponents.BENEFITS]: BenefitsForm,
  [AvailableComponents.CONTACT_FORM]: SpaceForm,
  [AvailableComponents.DISCLAIMER]: DisclaimerForm,
  [AvailableComponents.DOWNLOAD_DOCUMENTS]: DocumentsForm,
  [AvailableComponents.HERO]: HeroForm,
  [AvailableComponents.INFORMATION_SECTION]: InformationSectionForm,
  [AvailableComponents.INSURANCE_SCOPE]: InsuranceScopeForm,
  [AvailableComponents.INVESTMENT_CALCULATOR_PLACEHOLDER]: SpaceForm,
  [AvailableComponents.LOGOS]: SpaceForm,
  [AvailableComponents.LOGOS_ARVAL]: LogosArvalForm,
  [AvailableComponents.VIDEO]: VideoForm,
  [AvailableComponents.PACKAGE_TABLE]: PackageTableForms,
  [AvailableComponents.BENEFITS_TABLE]: BenefitsTableForm,
  [AvailableComponents.INCLUDED_SERVICES_LIST]: IncludedServicesListForm,
  [AvailableComponents.PACKAGE_DETAILS]: PackageDetailsForm,
  [AvailableComponents.HEADLINE_SECTION]: HeadlineForm,
  [AvailableComponents.SHARE_PLACEHOLDER]: () => null,
  [AvailableComponents.IMAGE_GRID]: ImageGridForm,
};

export const icons = [
  'ArmchairDollar',
  'Car',
  'Cows',
  'FastForwardCircle',
  'Irrigation',
  'Money',
  'PhotovoltaicsSun',
  'TickCircle',
  'ArrowDownSquare',
  'CarShield',
  'CowsShield',
  'GiftDollar',
  'Lands',
  'MoneyPlus',
  'PlantGrowth',
  'TickRounded',
  'ArrowUpSquare',
  'Cash',
  'Crops',
  'GlobeHands',
  'LandsArrowsUp',
  'NaturalDisaster',
  'PlantationSnowflake',
  'TickSharp',
  'ArrowsDirections',
  'CheckboxArrow',
  'CropsMoney',
  'HandBulb',
  'LaptopAccount',
  'PaperBookmark',
  'Play',
  'TodoListHand',
  'Banknote',
  'ChevronDown',
  'CropsShield',
  'HandCoins',
  'Like',
  'PaperBookmarkLock',
  'Search',
  'Tractor',
  'BarnPlus',
  'ChevronRight',
  'CurvedArrow',
  'HandGrid',
  'LinkedIn',
  'PaperBulb',
  'SearchBulb',
  'TractorShield',
  'Bulb',
  'Chickens',
  'Distributor',
  'HandPercentDown',
  'Mail',
  'PaperShield',
  'Shield',
  'Tweet',
  'Calendar',
  'ChickensShield',
  'Doc',
  'HandsParallel',
  'MapMarker',
  'Pdf',
  'ShieldPlantGrowth',
  'Umbrella',
  'CalendarFlower',
  'ClockDollar',
  'Emission',
  'HeartEcg',
  'MessagesBulb',
  'People',
  'Signposts',
  'Wrench',
  'CalendarLeaf',
  'ClockRoundArrow',
  'Facebook',
  'HeartEcgShield',
  'MessagesEllipsis',
  'Percent',
  'SimpleHouse',
  'CallcenterHeadset',
  'CloseX',
  'Farm',
  'House',
  'Messenger',
  'Phone',
  'SquaresOverlapping',
  'CallcenterPerson',
  'Cow',
  'FarmShield',
  'Info',
  'Mobile',
  'PhotovoltaicsShield',
  'Star',
  'X2'
];

export const spaces = [
  {
    label: 'brak',
    value: 0
  },
  {
    label: 'xs',
    value: '8px'
  },
  {
    label: 'sm',
    value: '16px'
  },
  {
    label: 'm',
    value: '32px'
  },
  {
    label: 'lg',
    value: '64px'
  },
  {
    label: 'xl',
    value: '92px'
  },
  {
    label: 'xxl',
    value: '128px'
  }
];
