import { useSelector } from 'react-redux';
import { sprintf } from 'sprintf-js';
import API from '../../services/api';

const URLS = {
  ARTICLES_BANNERS: '/api/v1/articles-banners/',
  ARTICLES_BANNERS_SLUG: '/api/v1/articles-banners/%s/'
};

export function useArticleBannersApi() {
  const token = useSelector((state) => state.user.token.key);

  const fetchBanners = (config = {}) =>
    API.get(URLS.ARTICLES_BANNERS, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...config
    });

  const createBanner = (data) => {
    return API.post(URLS.ARTICLES_BANNERS, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const editBanner = (data) =>
    API.patch(sprintf(URLS.ARTICLES_BANNERS_SLUG, data.id), data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const removeBanner = (slug) =>
    API.delete(sprintf(URLS.ARTICLES_BANNERS_SLUG, slug), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const getBanner = (id, language = 'pl') => {
    return API.get(sprintf(URLS.ARTICLES_BANNERS_SLUG, id), {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        lang_code: language.toUpperCase()
      }
    });
  };

  return {
    editBanner,
    fetchBanners,
    createBanner,
    removeBanner,
    getBanner
  };
}
