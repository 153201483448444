import React from 'react';
import ReactTable from 'react-table';
import { useHistory, generatePath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

import { ResourceState } from '../../api/prf.api';
import { publishStatus } from '../../consts/prf.const';
import { searchValue } from './ProductsListFilters';

import {
  selectProducts,
  selectProductsResourceState
} from '../../state/prf.selectors';
import { deleteProduct } from '../../state/prf.actions';

const ProductsList = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const productsResourceState = useSelector(selectProductsResourceState);
  const history = useHistory();

  return (
    <ReactTable
      columns={[
        {
          Header: 'Nazwa (PL/GB)',
          accessor: 'name',
          Cell: ({ value }) => `${value.pl} / ${value.gb}`,
          filterMethod: searchValue
        },
        {
          Header: 'Specjalista',
          accessor: 'creator',
          Cell: ({ value }) =>
            !!value
              ? `${value.first_name} ${
                  !!value.last_name ? value.last_name : ''
                }`
              : '-'
        },
        {
          Header: 'Status',
          accessor: 'publish_status',
          Cell: ({ value }) => `${publishStatus[value]}`
        },
        {
          Header: '',
          accessor: 'id',
          sortable: false,
          filterable: false,
          maxWidth: 72,
          Cell: ({ value }) => (
            <Button
              size="sm"
              color="danger"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (window.confirm('Czy napewno chcesz usunąć?')) {
                  dispatch(deleteProduct(value));
                }
              }}
            >
              Usuń
            </Button>
          )
        }
      ]}
      data={products}
      filterable
      loading={productsResourceState === ResourceState.FETCHING}
      getTrProps={(state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
          return {
            onClick: (e) => {
              history.push(
                generatePath('/products/:slug', {
                  slug: rowInfo.original.id
                })
              );
            }
          };
        } else {
          return {};
        }
      }}
    />
  );
};

export default ProductsList;
