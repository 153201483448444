import { useEffect, useState } from 'react';
import { useArticleBannersApi } from './ArticlesBanners.api';
import { useSelector } from 'react-redux';

export const useArticlesBanner = (id) => {
  const lang = useSelector((state) => state.settings.lang);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { getBanner } = useArticleBannersApi();

  const fetchBanners = async () => {
    setLoading(true);
    try {
      const response = await getBanner(id, lang);

      setData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: loading,
    data
  };
};
