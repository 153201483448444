import React from 'react';
import Layout from '../../components/Layout';
import ArticlesBannerForm from '../../components/ArticlesBanners/ArticlesBannerForm';
import { useArticlesBanner } from '../../components/ArticlesBanners/ArticlesBanners.hooks';

function EditArticlesBanner({ id }) {
  const { data } = useArticlesBanner(id);

  return (
    <Layout>
      <h1 className="h2 pb-md-4 page-header">Edytuj baner #{id}</h1>
      <ArticlesBannerForm data={data} />
    </Layout>
  );
}

export default EditArticlesBanner;
