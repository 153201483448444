import React from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  UncontrolledAccordion
} from 'reactstrap';
import { UserAccessArticle } from '../../common/Articles/accessnew';
import { HighlightArticle } from '../../common/Articles/highlight';
import { FormLabels } from '../../common/Labels';
import { selectUserType } from '../../services/redux/reducers/user/selectors';
import { DatepickerField } from '../../common/Inputs';
import ArticlesBannersField from '../../common/ArticlesBanners/ArticlesBannersField';

export const Sidebar = ({
  children,
  values,
  handleChange,
  setFieldValue,
  report,
  errors,
  setErrors
}) => {
  const userType = useSelector(selectUserType);

  return (
    <div className="form-sidebar">
      {children}
      <UncontrolledAccordion
        stayOpen
        defaultOpen={['1', '2', '3', '4', '5', '6']}
      >
        <AccordionItem>
          <AccordionHeader targetId="5">Wyróżnij artykuł</AccordionHeader>
          <AccordionBody accordionId="5">
            <HighlightArticle
              value={values.featured}
              setFieldValue={(val) => setFieldValue('featured', val)}
            />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="1">Status</AccordionHeader>
          <AccordionBody accordionId="1">
            <FormGroup>
              <Input
                type="select"
                name="publish_status"
                id="publish_status"
                value={values.publish_status}
                onChange={handleChange}
              >
                <option value="1">Szkic</option>
                <option value="2">Do publikacji, czekający na akcept</option>
                {userType === 1 || userType === 2 ? (
                  <option value="3">Opublikowany i zaakceptowany</option>
                ) : null}
              </Input>
            </FormGroup>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="3">Dostępność</AccordionHeader>
          <AccordionBody accordionId="3">
            <UserAccessArticle
              value={values.access}
              setFieldValue={setFieldValue}
            />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="6">Banner</AccordionHeader>
          <AccordionBody accordionId="6">
            <ArticlesBannersField
              value={values.banner}
              setFieldValue={(val) => setFieldValue('banner', val)}
            />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="4">Etykieta</AccordionHeader>
          <AccordionBody accordionId="4">
            <FormLabels
              value={values.labels}
              setFieldValue={(val) => setFieldValue('labels', val)}
              access={values.access}
            />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2">Data publikacji</AccordionHeader>
          <AccordionBody accordionId="2">
            {!report && (
              <DatepickerField
                name="published_at"
                label="Dla wszystkich użytkowników"
                validation={errors.published_all_at}
                value={values.published_all_at}
                onChange={(date) => {
                  setFieldValue('published_all_at', date);
                  errors.published_all_at &&
                    setErrors({ ...errors, published_all_at: null });
                }}
              ></DatepickerField>
            )}
            <DatepickerField
              name="published_at"
              label="Dla użytkowników premium"
              validation={errors.published_at}
              value={values.published_at}
              onChange={(date) => {
                setFieldValue('published_at', date);
                errors.published_at &&
                  setErrors({ ...errors, published_at: null });
              }}
            ></DatepickerField>
          </AccordionBody>
        </AccordionItem>
      </UncontrolledAccordion>
    </div>
  );
};
